@tailwind base;
@tailwind components;
@tailwind utilities;

#nprogress .bar {
    background: #F36012 !important;
}
#nprogress .spinner-icon {
    color: #F36012 !important;
    border-top-color: #F36012 !important;
    border-left-color: #F36012 !important;
}

@layer base {
    @font-face {
        font-family: "MontSerrat Bold";
        src: url("../src/assets/fonts/Montserrat-Bold.ttf") format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "Montserrat Regular";
        src: url("../src/assets/fonts/Montserrat-Regular.ttf") format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "Source Sans";
        src: url("../src/assets/fonts/SourceSans3-Bold.ttf") format("truetype");
        font-display: swap;
    }
    html {
        @apply font-montserratR;
        color: #012346;
    }
}

.technologies-image{
    background-image: url(../src/assets/images/TechnologiesImg/technologies-mbl.png);
    background-position: center;
    background-repeat: no-repeat;
}


@media (min-width: 768px) {
    .technologies-image{
        background-image: url(../src/assets/images/TechnologiesImg/technologies.png);
        background-position: center;
        background-repeat: no-repeat;
    }
}



.globe-section{
    background-image: url(../src/assets/images/vg-globe-design.png);
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
}

.quote-background,
.stars {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.stars {
    background-image: radial-gradient(2px 2px at 10px 20px, #eee, rgba(0, 0, 0, 0)), radial-gradient(2px 2px at 40px 70px, #fff, rgba(0, 0, 0, 0)), radial-gradient(2px 2px at 90px 40px, #ddd, rgba(0, 0, 0, 0));
    background-repeat: repeat;
    background-size: 200px 200px;
    opacity: 0;
    animation: zoom 15s infinite;
}

.stars:nth-child(1) {
    background-position: 50% -40%;
    animation-delay: 0s;
}

.stars:nth-child(2) {
    background-position: 20% 60%;
    animation-delay: 2s;
}

.stars:nth-child(3) {
    background-position: 40% -80%;
    animation-delay: 4s;
}

.stars:nth-child(4) {
    background-position: -20% -30%;
    animation-delay: 6s;
}

.stars:nth-child(5) {
    background-position: 80% 30%;
    animation-delay: 8s;
}

@keyframes zoom {
    0% {
        opacity: 0;
        transform: scale(0.5);
        animation-timing-function: ease-in;
    }

    85% {
        opacity: 1;
        transform: scale(1.8);
        animation-timing-function: linear;
    }

    100% {
        opacity: .2;
        transform: scale(2.2);
    }
}


.contacts-title::after {
    content: '';
    border-bottom: 5px solid #F35F12;
    border-radius: 2px;
    display: block;
}

.swiper-button-next,
.swiper-button-prev ,
.career-chevron-btn{
    color: #f36012 !important;
}


.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    display: none;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 10px !important;
}

.swiper-pagination-bullet {
    height: 15px !important;
    width: 15px !important;
    background-color: #C4C4C4 !important;
}

.swiper-pagination-bullet-active {
    background-color: #F35F12 !important;
}

.clip-ellipse{
    clip-path: ellipse(52% 18% at 50% 54%);
}
.tech-icons:hover .icon-title {
    opacity: 1;
}
.tech-icons .icon-img:hover{
    opacity: 0;
    transition: opacity 0.8s;
    transition-duration: 1000ms;
}

.career-disc-border{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.career-title{
font-weight: 900;
font-size: 16px;
line-height: 158.47%;
color:#012346;
}


.underlineBox {
    display: flex;
    justify-content: center;
}
.underlineText {
    border-bottom: 5px solid #F35F12;
    width:100px;
    
}
.formError {
    color: red;
}

.loader {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border-radius: 50%;
    perspective: 800px;
    margin-left: auto;
    margin-right: auto;
}

.inner {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.inner.one {
    left: 0%;
    top: 0%;
    animation: rotate-one 1s linear infinite;
    border-bottom: 4px solid #f36012;
}

.inner.two {
    right: 0%;
    top: 0%;
    animation: rotate-two 1s linear infinite;
    border-right: 4px solid #f36012;
}

.inner.three {
    right: 0%;
    bottom: 0%;
    animation: rotate-three 1s linear infinite;
    border-top: 4px solid #f36012;
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(245 240 240 / 50%);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}